import { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import * as XLSX from 'xlsx';
import Papa from "papaparse";
import { CSVLink } from "react-csv";
import axios from "axios";

import "./App.css";

function App() {
	//State to store table Column name
	const [tableRows, setTableRows] = useState([]);

	//State to store the values
	const [values, setValues] = useState([]);
	const [csvData, setcsvData] = useState([]);
	const [dataWithToken, setDataWithToken] = useState();
	const [isLoading, setIsloading] = useState(false);
	const [isLoadingReport, setIsloadingReport] = useState(false);

	let yesterday = new Date();
	yesterday.setDate(yesterday.getDate() - 1);

	const [fromdate, onFromDateChange] = useState(yesterday);
	const [toDate, onToDateChange] = useState(new Date());


	function formatDate(date) {
		// Get individual components of the date
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
		const day = String(date.getDate()).padStart(2, '0');

		// Format the date as a string
		const formattedDate = `${year}-${month}-${day}`;
		return formattedDate;
	}

	// Function to handle data and generate Excel file
	const handleGenerateExcel = async () => {

		const { data1, data2 } = await fetchData();

		getFlattenedData(data1, data2)


	};

	// Function to fetch data from APIs
	const fetchData = async () => {
		setIsloadingReport(true)

		try {
			const apiUrl1 = `${process.env.REACT_APP_USER_API_URL}/sales-api/report`;
			
			const response1 = await axios.post(apiUrl1,
				{ "startDate": formatDate(fromdate), "endDate": formatDate(toDate) }
			);
			const data1 = response1?.data?.data?.meetingList

			const apiUrl2 = `${process.env.REACT_APP_USER_API_URL}/sales-api/report/site-visit`;
			const response2 = await axios.post(apiUrl2,
				{ "startDate": formatDate(fromdate), "endDate": formatDate(toDate) }
			);

			const data2 = response2?.data?.data?.siteVisited;

			return { data1, data2 };
		} catch (error) {
			
			return { data1: [], data2: [] };
		}
	};


	const getFlattenedData = (data1, data2) => {

		const flattenedData = data1.flatMap(data =>
			data.meetingConducted.flatMap(meeting =>
				meeting.participants.flatMap(participant => ({

					roomId: data.roomId ?? "-",
					userName: data.userName ?? "-",
					email: data.email ?? "-",
					lastVisitedTime: data.lastVisitedTime ?? "-",
					meetingName: meeting.meetingName ?? "-",
					creatorName: meeting.creatorName ?? "-",
					participantName: participant.name ?? "-",
					participantEmail: participant.email ?? "-",
					joinTime: participant.joinTime ?? "-",
					leaveTime: participant.leaveTime ?? "-",
					feedbacks: meeting.feedbacks.map(fb => `${fb.userName} (${fb.organization}): ${fb.comments}`).join('; ')
				})),

			)
		);

		const formattedFromDate = formatDate(fromdate)
		const formattedToDate = formatDate(toDate)

		convertToCSV(flattenedData, `Meetings_conducted_${formattedFromDate} to ${formattedToDate}_report.csv`);
		convertToCSV(data2, `Site_visits_${formattedFromDate} to ${formattedToDate}_report.csv`)

		

	}

	const convertToCSV = (result, fileName) => {

		

		if (result?.length === 0) {
			
			result = [['No result found']]
		}
		// Create a new workbook
		const wb = XLSX.utils.book_new();
		// Convert data to sheets
		const ws1 = XLSX.utils.json_to_sheet(result); // Create sheet from data1

		// Define column widths
		ws1['!cols'] = [
			{ wpx: 200 },
			{ wpx: 200 },
			{ wpx: 200 },
			{ wpx: 150 },
			{ wpx: 200 },
			{ wpx: 200 },
			{ wpx: 200 },
			{ wpx: 200 },
			{ wpx: 200 },
			{ wpx: 200 },
			{ wpx: 1000 },

		];

		// Append sheets to workbook
		XLSX.utils.book_append_sheet(wb, ws1, 'Sheet1');

		// Write workbook to binary string
		const wbout = XLSX.write(wb, { bookType: 'csv', type: 'binary' });

		// Convert binary string to Blob
		const buf = new ArrayBuffer(wbout.length);
		const view = new Uint8Array(buf);
		for (let i = 0; i < wbout.length; i++) view[i] = wbout.charCodeAt(i) & 0xFF;
		const blob = new Blob([buf], { type: 'application/octet-stream' });

		//Create a link element and trigger a download
		const url = window.URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', fileName); // Set the file name
		document.body.appendChild(link);
		link.click();

		// Clean up
		link.parentNode.removeChild(link);
		window.URL.revokeObjectURL(url);

		setIsloadingReport(false)


	}



	const changeHandler = (event) => {
		setcsvData([]);
		setValues();
		setDataWithToken();
		setIsloading(true);
		// Passing file data (event.target.files[0]) to parse using Papa.parse
		Papa.parse(event.target.files[0], {
			header: true,
			skipEmptyLines: true,
			complete: function (results) {

				if (Object.keys(results.data[0]).length === 13) {
					const rowsArray = [];
					const valuesArray = [];
					// Iterating data to get column name and their values
					results.data.map((d) => {
						rowsArray.push(Object.keys(d));
						valuesArray.push(Object.values(d));
					});
					// Filtered Column Names
					rowsArray[0].push("Meeting URL", "Tokenize URL");
					setTableRows(rowsArray[0]);

					// Filtered Values
					setValues(valuesArray);
				} else {
					alert("Please upload correct file");
					setcsvData([]);
					setValues();
					setDataWithToken();
					setIsloading(false);
				}
			},
		});
	};

	const fetchTokens = async () => {
		try {
			let allUserData = [];
			const pages = Math.ceil(values.length / 20);
			for (let i = 0; i < pages; i++) {
				const userDataPage = values.slice(i * 20, (i + 1) * 20);
				const usersDetails = await Promise.all(
					userDataPage?.map((userData, index) => {
						return new Promise(async (resolve, reject) => {
							try {
								console.log(
									"userData",
									Array.isArray(userData[userData.length - 1])
								);
								const participantName = `${userData[3]} ${userData[4]}`;
								const roomName = Array.isArray(userData[userData.length - 1])
									? userData[userData.length - 1][0]?.split("id=")[1]
									: userData[userData.length - 1]?.split("id=")[1];
								if (!roomName || roomName == "") {
									resolve([
										...userData,
										"",
										"Error: room_id not found in request",
									]);
									return;
								}
								const apiUrl = `${process.env.REACT_APP_USER_API_URL}/sales-api/create-token`;
								// const apiUrl = "http://localhost:4000/sales-api/create-token";
								const res = await axios.post(apiUrl, {
									room_id: roomName,
									user_name: participantName,
									email: userData[5],
								});
								const body = {
									room: `NGTest${index}`,
									participantName: "Nilesh Gawande",
								};
								const tokenRes = await axios.post(
									`${process.env.REACT_APP_ADMIN_API_URL}/api/token`,
									body
								);
								const token = tokenRes.data.token;
								const metUrl =
									`${process.env.REACT_APP_DEMO_URL}/#/` +
									res.data.data.room_id;

								const tokenizeURL =
									`${process.env.REACT_APP_JITSI_URL}/${body.room}?jwt=` +
									token;
								resolve([...userData, metUrl, tokenizeURL]);
							} catch (error) {

								resolve([...userData, "Error", JSON.stringify(error)]);
							}
						});
					})
				);
				allUserData = [...allUserData, ...usersDetails];
				setDataWithToken(allUserData);
			}

			setIsloading(false);
		} catch (error) {

			setIsloading(false);
			alert(`Something went wrong: ${JSON.stringify(error)}`);
		}
	};

	useEffect(() => {
		if (values?.length > 0) {
			fetchTokens();
		}
	}, [values]);

	useEffect(() => {
		if (dataWithToken?.length > 0) {
			const csvDataL = [tableRows, ...dataWithToken];
			setcsvData(csvDataL);
		}
	}, [dataWithToken]);

	
	return (
		<>
			{(isLoading || isLoadingReport) &&
				<div class="spinner-overlay text-primary">
					<div class="spinner-border" role="status">
					</div>
				</div>
			}
			<div className="main-div">
				<div
					style={{ width: "100%", padding: "15px 0px", backgroundColor: "#fff" }}
				>
					<img
						src={"/logo.png"}
						alt="logo"
						style={{ width: "9.9rem", marginLeft: "2.25rem" }}
					></img>
				</div>
				<div className="main-table-div">

					<div className="heading">Proconf Visit Report</div>
					<div>Specify the date range and click download to see the report detailing customers who visited proconf.live site,
						Customers who actually performed the meeting and invited participants and to review the customer feedback.
					</div>
					<div className="date-panel">

						<div className="date-input">
							<span>From Date</span>
							<DatePicker onChange={onFromDateChange} value={fromdate} clearIcon={null} />
						</div>
						<div className="date-input">
							<span>To Date</span>
							<DatePicker onChange={onToDateChange} value={toDate} clearIcon={null} />
						</div>


						<button onClick={handleGenerateExcel} disabled={isLoadingReport} className="downloadbtn"
						>
							Download Report
						</button>

					</div>

					<div className="custom-separator"></div>


					<div className="heading">Upload Meeting Data</div>
					<div className="label">Upload meeting data to whom you want to send ProCONF invite. You need to upload this data in the prescribed format.
						<a href={"/sample.csv"} download="SampleCSV" target="_blank">
							<button className="btn-download">(Download Sample CSV)</button>
						</a>
					</div>
					<div>            Please select the CSV file, to generate the meeting links.
					</div>

					<div style={{ display: "flex", marginTop: "1rem" }}>
						{/* File Uploader */}
						<input
							type="file"
							name="file"
							onChange={changeHandler}
							accept=".csv"
							style={{ textAlign: "left", display: "block", margin: "" }}
						/>
					</div>
					{isLoading && <h5 style={{ textAlign: "center" }}>Loading...</h5>}
					<br />
					{/* Table */}
					<div className="table-div">
						<table>
							<thead>
								<tr>
									{tableRows?.map((rows, index) => {
										return <th key={index}>{rows}</th>;
									})}
								</tr>
							</thead>
							<tbody>
								{dataWithToken?.map((value, index) => {
									return (
										<tr key={index}>
											{value.map((val, i) => {
												return <td key={i}>{val}</td>;
											})}
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
					<br />
					{csvData && values?.length > 0 && isLoading === false && (
						<div className="export-csv-btn-div">
							<CSVLink
								className="downloadbtn"
								filename="meeting_list.csv"
								data={csvData}
							>
								Export to CSV
							</CSVLink>
						</div>
					)}
				</div>
			</div>
		</>
	);
}

export default App;
